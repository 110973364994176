<template lang="pug">
q-card.column.justify-center.q-pa-md.q-mx-md.plash.bg-secondary.b-radius-16(v-if="showWindow")
    .justify-center.column.q-mb-sm.text-primary.items-center
        .row.items-center
            .text-center
                q-icon.q-mt-xs(
                    name="etabl:location-marker"
                    size="24px"
                    color="positive"
                )
            .text-weight-medium.q-mr-xs
                | Ваш город
            city-form-mobile(
                :menu="false"
                :home="true"
                )
            .text-weight-medium.q-ml-xs
                | ?   
    .row.justify-between
        base-btn.justify-center.items-center.cityCheck__button(
            label="Подтверждаю"
            loading_color="secondary"
            text_color="secondary"
            height="30"
            font_size="13"
            bradius="16"
            @click="accept"
        )
        city-form-mobile(
            :menu="false"
            :button="true"
        )
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useQuasar, LocalStorage } from 'quasar';
import { useStore } from 'vuex';
import CityFormMobile from 'components/CityForm/CityFormMobile.vue';
import BaseBtn from 'components/Base/BaseBtn.vue';

export default {
    components: {
        CityFormMobile,
        BaseBtn,
    },
    setup() {
        const store = useStore();

        const showWindow = ref(false);

        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);
        
        const accept = () => {
            LocalStorage.set('USER_CITY_ID', userCityId.value);
            showWindow.value = false;
        };

        // const width = computed(() => {
        //     return
        // })

        onMounted(() => {
            if(!LocalStorage.getItem('USER_CITY_ID')) {
                showWindow.value = true;
            }
        });
        
        return {
            showWindow,
            userCityId,
            accept,
        };
    },
};
</script>

<style lang="scss" scoped>
.q-card {
    background: $app-color-background;
}

.plash {
    position: absolute;
    width: calc(100% - 32px);
    z-index: 9;
}

.q-btn {
    width: calc(50% - 8px) !important;
}

</style>
